import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {
  IconDefinition,
  faCalendarAlt,
  faAngleDown,
  faQuestionCircle,
  faDownload,
  faPrint,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faStepBackward,
  faCaretLeft,
  faStepForward,
  faCaretRight
} from '@fortawesome/pro-solid-svg-icons';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ColDef } from 'ag-grid-community';
import { ReportCenterUser } from '../../../configs/model/report-center-users.model';
import { UsageLog, AuditActionLookup, UsageLogSearch, actionsContainerTitle, UsageLogGridHeader, UsageLogExtended } from '../../../configs/model/usage-log.model';
import { UserProfile } from '../../../configs/model/user-profile.model';
import { HelperService } from '../../../services/helper.service';
import { ReportCenterUsersService } from '../../../services/report-center-users.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { UiWidgetsService } from '../../../services/ui-widgets.service';
import { UsageLogService } from '../../../services/usage-log.service';
import { UserProfileService } from '../../../services/user-profile.service';
import { ArrayPaginator, ArrayPaginatorState } from '../../../shared/array-pagination';
import { deepSort, makeFileNameSafe, sort_distinct } from '../../../shared/utils/utils';
import { FinraGridHeaderComponent } from '../../fragments/finra-grid-header/finra-grid-header.component';
import { combineLatest } from 'rxjs';
import { UsageLogExportRequestsService } from 'src/app/services/usage-log-export-requests.service';
import { ReportTypeEdition } from 'src/app/configs/model/admin.model';
import { AdminService } from 'src/app/services/admin.service';
import { ReportsService } from 'src/app/services/reports.service';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ReportCategory, ReportType } from 'src/app/configs/model/reports.model';
import { BeastService } from 'src/app/services/beast.service';
import { BeastClickActions } from 'src/app/enums/beast.enum';



@Component({
  selector: 'report-usage-log',
  templateUrl: './usage-log.component.html',
  styleUrls: ['./usage-log.component.scss']
})
export class UsageLogComponent implements OnInit {
  isFinraAdmin: boolean = false;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  faCalendarAlt: IconDefinition = faCalendarAlt;
  faAngleDown: IconDefinition = faAngleDown;
  faDownload: IconDefinition = faDownload;
  faPrint: IconDefinition = faPrint;
  faSpinner: IconDefinition = faSpinner;
  // prev
  faStepBackward: IconDefinition = faStepBackward;
  faCaretLeft: IconDefinition = faCaretLeft;
  // next
  faStepForward: IconDefinition = faStepForward;
  faCaretRight: IconDefinition = faCaretRight;
  errorMessage: string;
  rowData: UsageLogExtended[] = [];
  result: UsageLogExtended[];
  logService: UsageLogService;
  columnDefs: ColDef[];
  title: string = 'Usage Log';
  id: string = 'usage-log';
  isViewable: boolean = true;
  clientHeight: number;
  footerHeight: number = 220;
  startDate: string = '2019-01-01';
  endDate: string = '2019-12-01';
  lookupMap: {};
  error: HttpErrorResponse;
  auditSearchFormGroup: FormGroup;
  auditActionLookups: Array<AuditActionLookup> = [];
  reportCenterUsers: Array<ReportCenterUser> = [];
  filteredReportCenterUsers: Array<ReportCenterUser> = [];
  showCrdExamNumber: boolean = false;
  showChangeFirm: boolean = false;
  headers: FinraGridHeaderComponent[] = [];
  loading: boolean = false;
  actionsContainerTitle: string = actionsContainerTitle;
  usersPaginator: ArrayPaginator;
  auditActionsDetailsToHide = {
    1: 'Login',
    7: 'Logoff',
    15: 'Usage Log View',
  };
  rcUserById = {};
  printState = {
    icon: faPrint,
    processing: false,
  };
  usersErrorMessage?: string;
  prevFirmCrdNumber?: string;

  reportTypesList: ReportType[] = [];
  reportTypeControl = new FormControl({ value: [], disabled: false }, []);

  get usersPaginatorState(): ArrayPaginatorState {
    const value = this.usersPaginator
      ? this.usersPaginator.getState()
      : (<any>{});
    return value;
  }

  get selectedUsersLength(): number {
    const value = this.getSelectedUsers();
    return value ? value.length : 0;
  }

  constructor(
    private usageLogService: UsageLogService,
    private usageLogExportRequestsService: UsageLogExportRequestsService,
    private reportCenterUsersService: ReportCenterUsersService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private userProfileService: UserProfileService,
    private helperService: HelperService,
    private toolbarService: ToolbarService,
    private uiWidgetsService: UiWidgetsService,
    private adminService: AdminService,
    private reportsService: ReportsService,
    private beastService: BeastService,
  ) {}

  ngOnInit() {
    this.clientHeight = window.innerHeight;
    this.titleService.setTitle('Usage Log');
    this.columnDefs = UsageLogGridHeader;
    this.userProfileService.isAdminUser(this.userProfileService.getProfileObj());

    this.initColumnDefs();
    this.initUsageLogForm();

    this.userProfileService.getUserProfile(true).subscribe({
      next: (userProfile: UserProfile) => {
        this.handleUserProfile(userProfile);
        // this.initUsageLogSearch();
        this.usageLogService.getAuditActionLookups().pipe(
          tap(
            (auditActionLookups: AuditActionLookup[]) => this.handleAuditActionLookups(auditActionLookups)
          ),
          take(1)
        )
        .subscribe({
          next: (auditActionLookups: AuditActionLookup[]) => {
            this.refreshGridData();
          },
          error: (error: HttpErrorResponse) => {
            this.handleError(error, 'audit actions');
          }
        });

        this.adminService.getReportTypes().subscribe({
          next: (reportTypes: ReportType[]) => {
            this.reportTypesList = deepSort(reportTypes, 'reportTypeName', false, true);
          }
        });

        this.reportTypeControl.valueChanges.subscribe({
          next: (value) => {
            console.log(value, this);
          }
        });
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error, "user profile");
      }
    });
  }

  initColumnDefs() {
    // TODO: Find a cleaner way to handle below functionality
    this.columnDefs.forEach(col => {
      const enableSorting =
        col['headerComponentParams'] &&
        col['headerComponentParams']['enableSorting'];
      if (enableSorting) {
        col['headerComponentParams']['context'] = this;
        col['headerComponentParams']['setSort'] = (
          sort: string,
          multiSort?: boolean
        ) => {
          if (this.auditSearchFormGroup.value.sortField === col.headerComponentParams.sortField || null) {
            this.auditSearchFormGroup.patchValue({
              sortField: col.headerComponentParams.sortField,
              sortOrder: this.auditSearchFormGroup.value.sortOrder === 'asc' ? 'desc' : 'asc'
            });
          } else {
            this.auditSearchFormGroup.patchValue({sortField: col.headerComponentParams.sortField, sortOrder: 'asc'});
          }

          this.refreshGridData();
        };
      }
    });
  }

  initUsageLogForm() {
    const filterUsersQueryFormControl = new FormControl('');
    filterUsersQueryFormControl.valueChanges.subscribe({
      next: (query: string) => {
        this.filterUsers(query);
      }
    });

    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    // for debugging purposes
    const formObj = {
      auditActionLookupIds: null,
      startDate: this.helperService.getTransportDate(startDate),
      endDate: this.helperService.getTransportDate(endDate),
      filterUsersQuery: filterUsersQueryFormControl,
      examCrdNumber: null,
      examNumber: null,
      firmCrdNumber: null,
      rcUserIds: null,
      sortField: null,
      sortFields: null,
      sortOrder: null,
      sortOrders: null
    };
    this.auditSearchFormGroup = this.formBuilder.group(formObj);
  }

  search(searchFilter: UsageLogSearch) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.usageLogService.searchUsageLogs(searchFilter).subscribe({
      next: (data: UsageLogExtended[]) => {
        this.handleGridData(data);
      },
      error: (error: HttpErrorResponse) => {
        this.handleGridDataError(error);
      }
    });

    const useFirmCrdNumber = (searchFilter || {}).firmCrdNumber || '$$all$$';
    if (this.prevFirmCrdNumber === useFirmCrdNumber) {
      return;
    }

    this.prevFirmCrdNumber = useFirmCrdNumber;
    this.usersErrorMessage = undefined;
    this.auditSearchFormGroup.get('rcUserIds').setValue(null);
    this.reportCenterUsersService.getUsersByOrgId(useFirmCrdNumber).subscribe({
      next: (reportCenterUsers: ReportCenterUser[]) => {
        this.handleReportCenterUsers(reportCenterUsers);
      },
      error: (error: HttpErrorResponse) => {
        this.handleReportCenterUsersError(error);
      }
    });
  }

  handleAuditActionLookups(auditActionLookups: AuditActionLookup[]) {
    if (!auditActionLookups) {
      return;
    }
    this.auditActionLookups = auditActionLookups;
    const idList = [];
    this.auditActionLookups.forEach((x) => {
      idList.push(x.auditActionLookupId);
      (<any>x).selected = true;
    });
    /**
     * Will not use the form control for managing audit action id selection.
     */
    // this.auditSearchFormGroup.get('auditActionLookupIds').setValue(idList);
  }

  handleUserProfile(user: UserProfile) {
    if (!user) {
      return;
    }
    this.auditSearchFormGroup.get('firmCrdNumber').setValue(user.orgId);
    this.showChangeFirm = !this.userProfileService.isExternalUser(user);
    const shouldShowCrdExamNumber = ['SRO', 'SEC'].includes(user.orgClass);
    this.showCrdExamNumber = shouldShowCrdExamNumber;
  }

  registerHeader(header: FinraGridHeaderComponent) {
    this.headers.push(header);
    if ((header.params as any).sortField === this.auditSearchFormGroup.value.sortField) {
      header.sorted = this.auditSearchFormGroup.value.sortOrder;
    }
  }

  onExportPdf() {
    const currentDateTime = new Date();
    const date = makeFileNameSafe(currentDateTime.toDateString());
    const time = makeFileNameSafe(currentDateTime.toLocaleTimeString());
    const useFileName = `Usage-Log-Export-${date}-${time}-${Date.now()}`;
    this.toolbarService.onExportPdf(useFileName)
      .then(() => {
        this.printState.icon = faPrint;
        this.printState.processing = false;
      });
  }

  deselectAll(controlName) {
    this.auditSearchFormGroup.get(controlName).setValue(null);
  }

  selectAll(controlName) {
    const values =
      controlName === 'auditActionLookupIds'
        ? this.auditActionLookups.map(x => x.auditActionLookupId)
        : this.reportCenterUsers.map(x => x.reportCenterUserId);
    this.auditSearchFormGroup.get(controlName).setValue(values);
  }

  handleReportCenterUsers(reportCenterUsers: ReportCenterUser[]) {
    if (!reportCenterUsers) {
      return;
    }
    this.reportCenterUsers = reportCenterUsers;
    this.filteredReportCenterUsers = [...this.reportCenterUsers];
    this.usersPaginator = new ArrayPaginator(this.filteredReportCenterUsers);
    this.usersPaginator.setSize(50);
    this.setAllUsersSelectedState(false);
  }
  handleReportCenterUsersError(error: HttpErrorResponse) {
    this.reportCenterUsers = [];
    this.filteredReportCenterUsers = [];
    this.usersPaginator = undefined;
    const errorMsg = this.handleError(error, 'users', true);
    this.usersErrorMessage = errorMsg;
    setTimeout(() => {
      this.usersErrorMessage = undefined;
    }, 3000);
  }

  filterUsers(query: string) {
    const filteredList = this.reportCenterUsers.filter((user: ReportCenterUser) => {
      const nameMatchesQuery = user.userName.includes(query);
      const emailMatchesQuery = user.userEmail.includes(query);
      const idMatchesQuery = user.reportCenterUserId.toString().includes(query);
      const match = (
        nameMatchesQuery ||
        emailMatchesQuery ||
        idMatchesQuery
      );
      return match;
    });
    this.filteredReportCenterUsers = filteredList;
    this.usersPaginator = new ArrayPaginator(this.filteredReportCenterUsers);
    this.usersPaginator.setSize(50);
  }

  getActionById(actionId: number) {
    return this.auditActionLookups.find(x => x.auditActionLookupId === actionId)
      .auditActionDescription;
  }

  getUserById(userId: number) {
    return this.reportCenterUsers.find(x => x.reportCenterUserId === userId)
      .userName;
  }

  getSelectedUsers(): Array<ReportCenterUser> {
    const selected = this.filteredReportCenterUsers.filter(
      (user: ReportCenterUser) => !!(<any>user).selected
    );
    return selected;
  }

  getSelectedAuditActions(): Array<AuditActionLookup> {
    const selected = this.auditActionLookups.filter((auditAction) => {
      const match = !!(<any>auditAction).selected;
      return match;
    });
    return selected;
  }

  refreshGridData() {
    this.search(this.getUsageLogsSearchFilter());
  }

  handleGridData(data: UsageLogExtended[]) {
    data.forEach(auditAction => {
      const shouldHideDetails = this.auditActionsDetailsToHide[auditAction.auditActionLookupId];
      if (shouldHideDetails) {
        auditAction.auditActionDetail = null;
      }
    });
    this.rowData = data;
    this.loading = false;
    this.error = null;
    const isEmpty = data.length === 0;
    if (isEmpty) {
      const errorMessage = `No results matched your query...`;
      this.uiWidgetsService.showErrorSnackbar(errorMessage);
    }
  }
  handleGridDataError(error: HttpErrorResponse) {
    this.loading = false;
    this.rowData = [];
    const errorMsg = this.handleError(error, 'usage log data', true);
    setTimeout(() => {
      this.uiWidgetsService.showErrorSnackbar(errorMsg);
    });
  }

  getUsageLogsSearchFilter(): UsageLogSearch {
    const result: UsageLogSearch = {} as UsageLogSearch;
    Object.keys(this.auditSearchFormGroup.value).forEach(key => {
      if (this.auditSearchFormGroup.value[key] != null) {
        result[key] = this.auditSearchFormGroup.value[key];
      }
    });
    const shouldSetRcUserIds = !result.rcUserIds && this.filteredReportCenterUsers;
    if (shouldSetRcUserIds) {
      const filteredUserIds = this.getSelectedUsers().map(user => user.reportCenterUserId);
      if (filteredUserIds.length) {
        result.rcUserIds = filteredUserIds;
      }
    }
    const shouldSetAuditActionIds = !result.auditActionLookupIds && this.auditActionLookups;
    if (shouldSetAuditActionIds) {
      const filteredAuditActionLookupIds =
        this.getSelectedAuditActions().map(auditAction => auditAction.auditActionLookupId);
      if (filteredAuditActionLookupIds.length) {
        result.auditActionLookupIds = filteredAuditActionLookupIds;
      }
    }
    if (this.reportTypeControl.value && this.reportTypeControl.value.length) {
      /*
        get the report types that were NOT selected
      */
      result.reportTypes = this.reportTypeControl.value;
    }
    return result;
  }

  setAllAuditActionsSelectedState(selectedState: boolean) {
    this.auditActionLookups.forEach((auditAction) => {
      (<any>auditAction).selected = selectedState;
    });
  }

  setAllUsersSelectedState(selectedState: boolean) {
    this.usersPaginatorState.copyArrayObject.forEach((user) => {
      (<any>user).selected = selectedState;
    });
  }

  private handleError(
    error: HttpErrorResponse,
    dataLabel: String,
    returnMessage: boolean = false,
  ): string | null {
    let errorMessage = '';

    switch (error.status) {
      case 401: {
        errorMessage = `You are not entitled to view this data, please contact your Super Account Administrator or the Report Center Administrator.`;
        break;
      }
      case 403: {
        errorMessage = `You are not entitled to view this data, please contact your Super Account Administrator or the Report Center Administrator.`;
        break;
      }
      case 404: {
        errorMessage = `No ${dataLabel} found.`;
        break;
      }

      default: {
        errorMessage = `Could not load ${dataLabel}...`;
        break;
      }
    }

    if (returnMessage) {
      return errorMessage;
    }
    else {
      this.uiWidgetsService.showErrorSnackbar(errorMessage);
      return null;
    }
  }

  onPrintGrid() {
    this.isViewable = !this.isViewable;
    const self = this;
    setTimeout(function () {
      self.isViewable = !self.isViewable;
    }, 4000);
  }

  openHelp() {
    window.open('https://www.finra.org/compliance-tools/report-center/technical-assistance', '_blank');
    this.beastService.clickStream.postEvent(BeastClickActions.USAGE_LOG_HELP, {});
  }

  exportUsageLogData() {
    this.usageLogService.exportUsageLogData(this.rowData);
  }

  exportUsageLogDataFull() {
    this.usageLogExportRequestsService.exportUsageLogDataFull(this.getUsageLogsSearchFilter());
  }
}
